<script setup lang="ts">

import { useConfigStore } from '@/stores/manageConfig';
import LevelProfitCard from '@/views/MyTeam/src/components/LevelProfitCard.vue';
import { getDownPromoteNumber } from '@/api/finance/trading/member/promoter';

defineOptions({
  name: 'TeamDesc'
});

const { getProfitRate } = toRefs(useConfigStore());

const profitRate = computed(() => {
  return getProfitRate.value.map(rate => parseInt(rate));
});

</script>

<template>
  <div class="q-pa-sm">
    <div class="text-body2 text-primary text-weight-bold">
      How it work👆
    </div>
    <!--说明-->
    <div>
      <div>
        1. Share links and invite your friends to form your own team
      </div>
      <div v-for="(rate, index) in profitRate" :key="index">
        {{ index + 2 }}. You obtain
        <span class="text-weight-bold">{{ rate }}% of your level {{ index + 1 }}</span>
        referrals' recharge amount as your bonus
      </div>
    </div>
<!--    <div class="q-py-sm q-gutter-md">-->
<!--      <LevelProfitCard v-for="(rate, index) in profitRate"-->
<!--                       :key="index"-->
<!--                       :level="index + 1"-->
<!--                       :effective-count="promoteNumber[index + 1]?.effectivePromoteCount || 0"-->
<!--                       :promote-count="promoteNumber[index + 1]?.promoteCount || 0"-->
<!--                       :rate="rate" />-->
<!--    </div>-->
  </div>
</template>

<style scoped>

</style>