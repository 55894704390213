<script setup lang="ts">

defineOptions({
  name: 'Wallet'
});

import { WithdrawPanel, WithdrawList } from '@/views/Wallet/src/components';

import { MyBalance } from '@/views/Common';

const withdrawListRef = ref();

</script>

<template>
  <q-page class="bg-grey-2 column no-wrap">
    <MyBalance />

    <!--银行卡和提现-->
    <WithdrawPanel class="q-pa-md"/>

    <WithdrawList ref="withdrawListRef" />
  </q-page>
</template>

<style scoped>

</style>