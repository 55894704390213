<script setup lang="ts">

import { fenToYuan, processPriceUnit } from '@/utils/price';
import { object } from 'vue-types';
import { OrderItemVO } from '@/api/finance/trading/order';
import { dateUtil, formatToDateTime } from '@/utils/dateUtil';

defineOptions({
  name: 'OrderItem'
});

const { push } = useRouter();

const OrderStatus = {
  unPaid: 0,
  cancel: 10,
  manualCompleted: 30,
  completed: 30
};

const props = defineProps({
  item: object<OrderItemVO>().isRequired
});

const { t } = useI18n();
const order = ref<OrderItemVO>(props.item);

const payStatus = computed(() => {
  return order.value.status;
});

const dailyReturnStart = computed(() => {
  if (payStatus.value === OrderStatus.unPaid) {
    return '/';
  }
  return dateUtil().hour(order.value.dailyIncomeTimeRange[0][0])
    .minute(order.value.dailyIncomeTimeRange[0][1])
    .format('HH:mm');
  // return order.value.dailyIncomeTimeRange[0][0] + ':' + order.value.dailyIncomeTimeRange[0][1];
});

const dailyReturnEnd = computed(() => {
  if (payStatus.value === OrderStatus.unPaid) {
    return '/';
  }
  return dateUtil().hour(order.value.dailyIncomeTimeRange[1][0])
    .minute(order.value.dailyIncomeTimeRange[1][1])
    .format('HH:mm');
});

const price = computed(() => {
  return fenToYuan(order.value.totalPrice);
});

const dailyIncome = computed(() => {
  return fenToYuan(order.value.dailyIncome);
});

const dailyIncomeRate = computed(() => {
  return parseFloat((dailyIncome.value / price.value * 100).toFixed(2));
});

const totalIncome = computed(() => {
  return dailyIncome.value * order.value.totalPeriod;
});

const totalIncomeRate = computed(() => {
  return parseFloat((totalIncome.value / price.value * 100).toFixed(2));
});

const currPeriod = computed(() => {
  if (payStatus.value === OrderStatus.unPaid) {
    return 0;
  }
  return order.value.currPeriod;
});

const currIncome = computed(() => {
  // return dailyIncome.value * currPeriod.value;
  return parseFloat((dailyIncome.value * currPeriod.value).toFixed(2));
});

const currIncomeRate = computed(() => {
  return parseFloat((currPeriod.value / order.value.totalPeriod * 100).toFixed(2));
});

const createTime = computed(() => {
  return formatToDateTime(order.value.createTime);
});

</script>

<template>
  <q-card class="full-width no-shadow radius-sm text-caption text-weight-bold">
    <!--时间订单号-->
    <q-card-section class="bg-common q-py-xs q-px-md row justify-between text-caption text-weight-bold">
      <div v-text="order.no"></div>
      <q-space />
      <div v-text="createTime"></div>
    </q-card-section>
    <q-separator />
    <!--产品-->
    <q-card-section class="q-pa-md">
      <div class="row text-h6 text-weight-bolder">
        <!--价格-->
        <div class="col text-h6 text-weight-bold" v-text="processPriceUnit(price)" />
        <!--产品名称-->
        <div class="col text-right text-no-wrap ellipsis" v-text="order.productName" />
      </div>
      <!--每日收益-->
      <div class="text-grey-6">
        {{t('order.dailyIncome') }}
        <span class="text-primary">{{ processPriceUnit(dailyIncome) }}</span>
        | {{ dailyIncomeRate }}%
      </div>
      <!--回报时间-->
      <div class="text-grey-6">{{ t('order.servingTime') }}
        <span class="text-primary">{{ currPeriod }}</span>/{{ order.totalPeriod }}
        {{ order.totalPeriod > 1 ? t('order.days') : t('order.day') }}
      </div>
      <!--每日计算时间-->
      <div class="text-grey-6">
        {{ t('order.dailyReturnTime') }} {{ dailyReturnStart }} - {{ dailyReturnEnd }}
      </div>
    </q-card-section>
    <q-separator />
    <!--income-->
    <q-card-section class="q-pa-none row text-black text-center text-body2 text-weight-bolder">
<!--      <div class="col q-pa-sm">-->
<!--        <div>{{ processPriceUnit(dailyIncome) }}</div>-->
<!--        <div class="text-primary">{{ dailyIncomeRate }}%</div>-->
<!--        <div class="text-caption text-weight-bolder">{{ t('order.dailyIncome') }}</div>-->
<!--      </div>-->
<!--      <q-separator inset vertical />-->
      <div class="col q-pa-sm">
        <div>{{ processPriceUnit(totalIncome) }}</div>
        <div class="text-primary">{{ totalIncomeRate }}%</div>
        <div class="text-caption text-weight-bolder">{{ t('order.totalIncome') }}</div>
      </div>
      <q-separator inset vertical />
      <div class="col justify-center q-pa-sm q-px-xs text-orange">
        <div>{{ processPriceUnit(currIncome) }}</div>
        <div>{{ currIncomeRate }}%</div>
        <div class="text-caption text-weight-bolder ellipsis">{{ t('order.alreadyObtained') }}</div>
      </div>
    </q-card-section>

    <!--遮罩-->
    <div v-if="order.status === OrderStatus.unPaid"
         style="opacity: 0.95" @click="push({name: 'PaymentRecords'})"
         class="bg-grey-6 fit absolute-center radius-sm
         text-h5 text-white text-center
         row items-center justify-center"
         v-text="t('order.orderNotPaid')"
    />
  </q-card>
</template>

<style scoped>

</style>