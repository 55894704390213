<script setup lang="ts">

defineOptions({
  name: 'Faq'
});

import { FaqVO, getFaqList } from '@/api/finance/manage/faq';
import FaqCard from '@/views/Faq/src/components/FaqCard.vue';
import { useConfigStore } from '@/stores/manageConfig';

const faqList = ref<FaqVO[]>([]);

const { toOfficialChannel } = useConfigStore();

const getList = async () => {
  faqList.value = await getFaqList();
};

onMounted(() => {
  getList();
});



</script>

<template>
  <q-page class="bg-grey-2 column hide-scrollbar">
    <!--轮播图-->
    <CommonCarousel type="faq" :ratio="16/9" />

    <!--faq列表-->
    <q-list class="q-pa-md q-gutter-md">
      <FaqCard
        v-for="item in faqList"
        :key="item.question"
        :item="item"
      />
    </q-list>

    <!--左下角按钮-->
    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-btn round unelevated size="md" color="primary" icon="headset" @click="toOfficialChannel" />
    </q-page-sticky>
  </q-page>
</template>

<style scoped>

</style>